<template>
  <div class="animate__animated animate__fadeInDown logo">
    <img class="logo-img" src="@/assets/logo.png" alt="">

    <a-dropdown class="u-dropdown" :trigger="['click']" v-if="$store.state.token && $store.state.userinfo">
        <a-icon type="user" />
        <a-menu class="dropdown-box" slot="overlay">
          <a-menu-item @click="$router.push({name:'wallet'})">
            <div class="menu-item menu-item-account">
              <a-icon class="icon" type="wallet" />
              <span>{{ $t('logo.myAssets')}}</span>
            </div>
          </a-menu-item>
          <a-menu-item @click="$router.push({name:'insurances'})">
            <div class="menu-item menu-item-withdraw">
              <a-icon class="icon" type="heart" />
              <span>{{ $t('logo.myInsurance')}}</span>
            </div>
          </a-menu-item>
          <a-menu-item @click="$router.push({name:'article',params:{id:3}})" >
            <div class="menu-item menu-item-detail">
              <a-icon class="icon" type="customer-service" />
              <span>{{ $t('logo.contactUs')}}</span>
            </div>
          </a-menu-item>
          <a-menu-item @click="$router.push({name:'invites'})">
            <div class="menu-item menu-item-team">
              <a-icon class="icon" type="apartment" />
              <span>{{ $t('logo.myTeam')}}</span>
            </div>
          </a-menu-item>
          <a-menu-item @click="logout">
            <div class="menu-item menu-item-exit">
              <a-icon class="icon" type="logout" />
              <span>{{ $t('logo.logout')}} </span>
            </div>
          </a-menu-item>
        </a-menu>
    </a-dropdown>
    <div class="login" v-else>
      <span @click="$router.push({name:'login'})">Log In</span> / 
      <span @click="$router.push({name:'register'})">Sign Up</span> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data(){
      return {}
  },
  methods: {
    logout(){
      let _this = this
      this.$http.call(this.$http.api.logout).then(()=>{
        _this.$store.commit("setToken","");
        _this.$store.commit("setUserinfo",{});
        _this.$router.go(0);
      });
    },
  }
}
</script>

<style lang="less" scoped>
.logo{
  padding: 15px 0;
  color: #ffedd2;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-img{
    height: 30px;
  }
}
.ant-dropdown-menu-item{
  border-bottom: 1px solid #d8d8d8;
  .icon {
    padding-right: 7px;
  }
}
.ant-dropdown-menu-item:last-child{
  border-bottom: 0;
}
</style>

