<template>
    <div class="home">
        <canvas style="--gradient-color-1:#1e3350;--gradient-color-2:#4e5a56;--gradient-color-3:#b18d7b;--gradient-color-4:#cf8f6e;" id="gradient-canvas" data-transition-in></canvas>
        <Logo />
        <div class="animate__animated animate__lightSpeedInLeft ta-left banner">
            <div class="font-bold h1">
                <div class="color-primary">
                    Insurance.
                </div>
                <div class="color-white">
                    Like never <br/>before.
                </div>
            </div>
            <div class="color-gray h3">
                With one platform for all your needs.<br/> Slide and tap to discover
            </div>
        </div>
        <div class="boxs animate__animated animate__bounceInRight animate__delay_2s ">
            <div class="wrapper">
                <div class="box nav1" @click="$router.push({name:'insurance'})">
                    <img class="icon" src="@/assets/imgs/navicon1.png" alt="">
                    <span class="title">
                        The world's first blockchain  smart contract
                    </span>
                </div>
                <div class="box nav2" @click="$router.push({name:'card'})">
                    <img class="icon" src="@/assets/imgs/navicon2.png" alt="">
                    <span class="title">
                        Professional and  considerate financial  services
                    </span>
                </div>
                <div class="box nav3">
                    <img class="icon" src="@/assets/imgs/navicon3.png" alt="">
                    <span class="title">
                        You are an  insurance  service expert
                    </span>
                </div>
                <div class="box nav4">
                    <img class="icon" src="@/assets/imgs/navicon4.png" alt="">
                    <span class="title">
                        DESIGNED  WITH YOU  BUILT FOR YOUR
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Logo from "../components/Logo.vue";
import Gradient from "../gradient";

export default {
    name: "Home",
    data() {
        return {
            userinfo: {
                address: '', balance:0.00, node_level:0,id:0
            },
        };
    },
    components: {
        Logo
    },
    mounted() {
        window.gradient = new Gradient;
        window.gradient.initGradient("#gradient-canvas");
    },
    methods: {
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                // console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);
            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        commingSoon(){
            this.$message.info(this.$t('common.commingsoon'));
        },
    },
};
</script>
<style lang="less" scoped>
.home {
    width:100%;
    padding: 15px;
    #gradient-canvas{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        opacity: .5;
    }
    .banner {
        margin-top: 200px;
        .h1{
            line-height: 44px;
        }
        .h3{
            padding: 15px 0;
            line-height: 20px;
        }
    }
    .boxs{
        width: 100%;
        border-radius: 10px;
        overflow-x: scroll;
        .wrapper{
            width: 845px;
            display: flex;
            .box{
                width: 200px;
                height: 200px;
                margin-right: 15px;
                position: relative;
                .icon{
                    height: 24px;
                    position: absolute; top: 15px;left: 15px;
                }
                .title{
                    position: absolute; bottom: 15px; left: 15px; right: 15px; text-align: left;
                    font-size: 20px;line-height: 22px; font-weight: bold;
                }
            }
            .box:last-child{
                margin-right: 0;
            }
            .nav1{
                background: url('~@/assets/imgs/nav1.png') no-repeat;
                background-size: cover;
                color: #f1d6d4;
            }
            .nav2{
                background: url('~@/assets/imgs/nav2.png') no-repeat;
                background-size: cover;
                color: #161c27;
            }
            .nav3{
                background: url('~@/assets/imgs/nav3.png') no-repeat;
                background-size: cover;
                color: #f1d6d4;
            }
            .nav4{
                background: url('~@/assets/imgs/nav4.png') no-repeat;
                background-size: cover;
                color: #161c27;
            }
        } 
    }
}
</style>
